strong {
  font-weight: 700 !important;
}

.confirmation-content {
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;

  span {
    background-color: transparent;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: revert;
    line-height: revert;
    font-size: revert;
    margin: revert;
  }

  pre {
    font-family: revert;
  }

  p {
    line-height: 1.4;
    margin: var(--sui-spacing-2) 0;
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: underline;
  }

  ol,
  ul {
    padding-left: var(--sui-spacing-4);
  }

  ul {
    list-style-type: revert;
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-alpha;

      ol {
        list-style-type: lower-roman;

        ol {
          list-style-type: lower-alpha;
        }
      }
    }
  }
}

.installmentLineItem-paid .sui-text-right {
  color: #0f5705;
}
