.InstallmentPreview__payment-timeline {
  position: relative;
  max-height: 328px;
  overflow: auto;
  width: 100%;
  list-style: none;
  .InstallmentPreview__payment-line-item {
    padding-bottom: 1em;
    padding-left: 20px;
    position: relative;

    &:before {
      content: '';
      background: #98a4ae;
      position: absolute;
      bottom: 0px;
      top: 0;
      left: 8px;
      width: 1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 6px;
      top: 10px;
      height: 4px;
      width: 4px;
      background: #1d2b35;
      border-radius: 50%;
    }
    &:first-of-type {
      &::before {
        top: 10px;
      }
    }
    &:last-of-type {
      &::before {
        bottom: 40px;
      }
    }
    > span {
      display: block;
    }
  }
  small {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    /* neutral/text-strong-neutral8 */

    color: #536878;
  }
}
