// Copied breakpoint from config.scss in @teamsnap/teamsnap-ui
$breakpoint-xs: 480px;
$box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.07);
$box-shadow-up: 0 -5px 10px 0 rgba(0, 0, 0, 0.07);
$border-color: #cbd0d6;
$header-height: 48px;
$max-width-small: 500px;
$max-width-large: 1440px;

.ActionContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .SelectBox::after {
    line-height: 44px;
  }

  /* Start of DateTimePicker styling */
  .date-time-picker {
    border: 1px solid #cbd0d6;
    box-shadow: $box-shadow;
  }

  .date-time-picker input {
    box-shadow: none;
    padding: 0 6px;
  }

  &-header {
    flex: 0 0 $header-height;
    justify-self: flex-start;
    border-bottom: 1px solid #cbd0d6;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.03);
    position: relative;

    > div {
      width: 100%;
      height: 100%;
      max-width: $max-width-large;
      margin: 0 auto;
      position: relative;
    }

    div[data-testid='profile-image-text'] {
      height: 36px !important;
      width: 36px !important;
    }

    .back-button {
      min-width: auto;
      min-height: auto;
    }
  }

  &-body {
    flex: 1 1 auto;
    height: 0px;
    min-height: 300px;
    overflow-y: scroll;
    background-color: var(--gray-1);
  }

  &-content {
    // height: 100%;

    > div {
      max-width: $max-width-small;
      margin: auto;
      min-height: 100%;
    }

    > div.format {
      padding-left: var(--sui-spacing-2);
      padding-right: var(--sui-spacing-2);
      padding-top: var(--sui-spacing-2);
      padding-bottom: var(--sui-spacing-2);
      background-color: #ffffff;
    }
  }

  &-footer {
    justify-self: flex-end;
    border-top: 1px solid #cbd0d6;
    box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.03);

    > div {
      max-width: $max-width-large;
      margin: 0 auto;
    }
    // box-shadow: $box-shadow-up;
    // height: max-content;

    // .Button {
    //   height: 48px;
    // }

    // @media (min-width: $breakpoint-xs) {
    //   box-shadow: none;
    // }
  }
}
