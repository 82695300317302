.waiver-content {
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif !important;
  height: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: revert;
    line-height: revert;
    font-size: revert;
  }

  pre {
    font-family: revert;
  }

  p {
    line-height: 1.4;
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: underline;
  }

  ol,
  ul {
    padding-left: var(--sui-spacing-2);
  }

  ul {
    list-style-type: revert;
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-alpha;

      ol {
        list-style-type: lower-roman;

        ol {
          list-style-type: lower-alpha;
        }
      }
    }
  }
}

[data-testid='modal-container-component'] {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 0px;
}

.WaiverFile--download-link {
  text-decoration: none !important;
}

.WaiverFile--download-link:hover {
  color: var(--sui-colors-action-text-hover) !important;
}

#digital-agreement__container {
  height: 100vh;
}

.WaiverFile--image--error  {
  object-fit: cover;
  width: 120px;
  height: 120px;
  text-indent: -99999999px;
  border: 1px solid var(--sui-colors-neutral-border);
}
