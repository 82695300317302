.Payment--field {
  // matches Stripe's input styles
  margin-top: 0.75rem;
  .Payment--label {
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0.75rem;
    font-size: 0.93rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-weight: 400;
    color: #30313d;
  }
  .Payment--input {
    position: relative;
    .Payment--input-input {
      color: #30313d;
      box-sizing: border-box;
      display: block;
      font-size: 1rem;
      padding: 0.75rem;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #e6e6e6;
      box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
      width: 100%;
      &:focus {
        outline: 0;
        border-color: hsla(210, 96%, 45%, 50%);
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%),
          0 1px 1px 0 rgba(0, 0, 0, 0.08);
      }
    }
    &.Payment--input-invalid .Payment--input-input {
      color: #df1b41;
      border-color: #df1b41;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px #df1b41;
    }
  }
  .Payment--error-message {
    color: #df1b41;
    font-weight: 400;
    font-size: 0.93rem;
  }
}

.Payment--link-text {
  color: #006ff5;
  background: none;
  display: inline;
  border: none;
  padding: 0;
  font-size: inherit;
}
