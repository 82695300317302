.Installments {
  .Installments__installment-option {
    position: relative;
    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    min-height: 48px;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;

    /* Base/White */

    background: #ffffff;
    /* Base/Gray 80 */

    border: 1px solid #d1d1d1;
    border-radius: 5px;
  }
  .Installments__installment-option-selected {
    border-color: #0f52ba;
    &:before {
      border-color: #0f52ba;
    }
  }
  small {
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    /* neutral/text-strong-neutral8 */

    color: #536878;
  }
}
