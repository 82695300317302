.accordion {
  button.accordion-trigger {
    width: 100%;
    border: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
    color: black;

    svg {
      transform-origin: center;
      transform: rotate(-90deg);
      transition: all 0.15s linear;
    }

    span {
      color: black;
    }
  }

  .accordion-content {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
  }

  &--open {
    .accordion-content {
      overflow: initial;
      height: auto;
      max-height: 2000px;
      transition: max-height 0.25s ease-in;
    }

    button.accordion-trigger {
      svg {
        transform-origin: center;
        transform: rotate(0deg);
      }
    }
  }
}
